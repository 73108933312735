.title-separator {
  display: flex;
  justify-content: space-between;
  color: #5a5a5a;
  border-bottom: 1px solid rgba(90, 90, 90, .15);
  margin: .5rem 0;

  i {
    margin-right: .5rem;
  }

  .actions {
    cursor: pointer;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
}
