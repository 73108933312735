.modal-content {
  z-index: 9999 !important;
  .modal-header {
    display: flex;
    align-items: center;

    padding: .5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #5a5a5a;

    i {
      margin-right: .5rem;
    }
  }

  .modal-body {
    max-height: 75vh;
    overflow: auto;
  }


  .modal-footer {
    padding: 0.25rem .5rem;
  }
}
