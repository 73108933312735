$bg-color: rgb(255, 255, 255);
$bg-odd-color: rgb(248, 248, 248);
$opt-color: rgb(90, 90, 90);

$border: 1px solid darken($color: $bg-odd-color, $amount: 5%);

@each $variant in (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25) {
  .col-#{$variant}rem {
    width: $variant * 1rem !important
  }
}

.table-ladder {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;

  tr, td, th {
    // min-height: 2rem;
    vertical-align: middle !important;
    padding: .5rem;
  }

  td, th {

    &.boolean {
      width: 5rem;
      text-align: center;
    }

    &.select {
      cursor: pointer;
      width: 3rem;
      text-align: center;
      font-size: 1rem;
    }

    &.money {
      text-align: right;
      width: 7rem;
    }

    &.currency {
      text-align: right;
      width: 5rem;
    }

    &.date {
      width: 5rem;
      text-align: center;
    }

    &.datetime {
      width: 8rem;
    }
  }

  thead {
    tr {
      border-bottom: $border;
    }
  }

  tbody {
    tr {
      td {
        &.link {
          cursor: pointer;
          // text-decoration: underline
          color: var(--primary);
        }

        &.options {
          cursor: pointer;
          width: 0.01rem;
          padding: 0;
          margin-bottom: 0;
          position: relative;
        }

        .icons-box {
          position: absolute;
          top: 0;
          right: 0;

          height: 100%;
          width: max-content;

          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;

          font-size: 1rem;
          color: transparentize($color: $opt-color, $amount: .25);

          i {
            display: inline-flex;
            padding: 0 .5rem;
          }

          .open-options, .close-options {
            color: transparentize($color: $opt-color, $amount: .75) !important;
          }

          &.hide {
            .close-options, .option {
              display: none;
            }
          }

          &.show {
            .close-options, .open-options {
              display: none;
            }
          }

          .option:hover {
            color: $opt-color;
          }
        }

        &:not(:last-child) {
          border-right: $border;
        }
      }

      &:hover {
        td {
          .icons-box {
            background-color: transparentize($color: $bg-color, $amount: .05);

            &.hide {
              .close-options, .option {
                display: inline-flex;
              }

              .open-options {
                display: none;
              }
            }
          }
        }
      }
    }

    & > tr:nth-child(odd) {
      background-color: $bg-odd-color;

      td {
        &.link:hover {
          background-color: darken($bg-odd-color, 5%);
          font-weight: bold;
        }

        &.options {
          .icons-box {
            background-color: transparentize($color: $bg-odd-color, $amount: .10);
          }
        }
      }
    }

    & > tr:nth-child(even) {
      td {
        &.link:hover {
          background-color: darken($bg-color, 5%);
          font-weight: bold;
          // background-color: darken($bg-odd-color, 10%);
        }
      }
    }
  }

  tfoot {
    .select {
      color: transparentize($color: $opt-color, $amount: .25)
    }
    tr {
      border-top: $border;
      font-weight: bold;

      td {
        border: none;
      }
    }
  }

  &.table-mobile {
    tbody {
      .mobile-box {
        display: flex;
        flex-direction: column;
        background-color: white;
        border: 1px solid rgba(90, 90, 90, .15);
        margin: .5rem .5rem 1rem .5rem;
        border-radius: .5rem;
        overflow: hidden;

        tr {
          display: flex;
          width: 100% !important;
          padding: 0 .25rem;
          border-bottom: 1px solid darken($color: $bg-odd-color, $amount: 5%);

          td {
            display: flex;
            align-items: center;
            // justify-content: center;
          }

          .col-label {
            font-weight: bold;

            width: 35%;
            padding-right: 1rem;
            justify-content: flex-end;
          }

          .col-value {
            width: 65%;
            justify-content: flex-start;
          }

          &.mobile-header {
            background-color: #f7f7f7;

            .select {
              justify-self: flex-start;
              width: 3rem;

            }

            .icons-box {
              display: flex;
              flex-grow: 10;
              align-items: center;
              justify-content: space-evenly;
              padding: .25rem 0;

              font-size: 1rem;
              color: transparentize($color: $opt-color, $amount: .25);

              .option-box {
                display: flex;
                flex-direction: column;
                // align-items: center;
                text-align: center;

                .option {
                  cursor: pointer;

                  &:hover {
                    color: $opt-color;
                  }
                }

                .option-title {
                  font-size: .75rem;
                }
              }
            }

            .boolean {
              justify-self: flex-end;
              width: 3rem;
            }
          }
        }

        // &:nth-child(odd) {
        //   background-color: $bg-odd-color;
        // }
      }
    }
  }
}
