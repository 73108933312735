$border: 1px solid rgba(90, 90, 90, .15);
$card-space: .5rem;

// Caso altere a largura do card, alterar cálculo no @media only no final do código
$dash-card-box-width: 15rem;

.dashboard {
  .card-header {
    color: #5a5a5a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem .25rem .25rem .5rem;

    .card-title {
      font-size: 1.25rem;
      font-weight: bold;
      display: inline-flex;
      margin: 0;
    }

    .card-actions {
      display: inline-flex;
      .search {
        display: inline-flex;
        justify-self: flex-end;
        align-items: center;

        * {
          &:not(:last-child) {
            margin-right: $card-space;
          }
        }
      }
    }
  }


  .dash-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: $card-space;

    .dash-card-box {
      display: inline-flex;
      flex-direction: row;
      // flex-wrap: wrap;

      width: $dash-card-box-width;
      cursor: pointer;

      border: $border;
      border-radius: $card-space;
      overflow: hidden;

      // &:not(:last-child) {
      //   margin-right: $card-space;
      // }

      .dash-card-icon {
        width: 30%;
        font-size: 2.25rem;
        align-items: center;
        justify-content: center;
        display: inline-flex;
      }

      .dash-card-info {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        color: var(--gray-dark);

        width: 70%;
        padding: 0 .25rem;

        .info-title {
          align-self: flex-start;
          margin: 0;

          font-size: .75rem;
          cursor: inherit;
        }

        .info-value {
          font-size: calc(1rem + 0.3vmax);
          font-weight: bold;
          cursor: inherit;

          margin: 0;
          padding: .25rem;
        }
      }

      .dash-card-footer {
        width: 100%;
        display: flex;
        padding: 0 $card-space;
        border-top: $border;
        background-color: rgba(90, 90, 90, .05);
      }
    }
  }

  @media only screen and (max-device-width: 59rem) {
    .dash-card-row {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .dash-card-box {
        width: 49%;
        margin-bottom: $card-space;
      }

      // &:not(:last-child) {
      //   margin-right: 0;
      // }
    }
  }

  @media only screen and (max-device-width: 29rem) {
    .dash-card-row {
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .dash-card-box {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: $card-space;
        }
      }
    }
  }

  @media only screen and (min-device-width: 60rem) {
    .dash-card-row {
      flex-direction: row;
      justify-content: space-between;

      .dash-card-box {
        &:not(:last-child) {
          margin-right: $card-space;
        }
      }
    }
  }
}
