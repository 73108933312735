.main {
  position: relative;
}

.loader-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(1px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 99999;

  .loader-spinner {
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
  }

  .loader-spinner div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .loader-spinner div:nth-child(2) {
    animation-delay: -1s;
  }

  .loader-hint {
    color: #FFF;
  }
}

@keyframes lds-ripple {
  0% {
    top: 2.5rem;
    left: 2.5rem;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 5rem;
    height: 5rem;
    opacity: 0;
  }
}
