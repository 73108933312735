p-calendar {
  .ui-calendar {
    .ui-inputtext {
      text-align: center;

      // Mesmo tamanho dos controls do form-group
      height: calc(1.5em + 0.75rem + 2px);
    }
  }
}

.form-group {
  p-calendar {
    .ui-calendar {
      width: 100%;
    }
  }

  &:has(+ app-field-input-switch) {
    display: flex;
    align-items: flex-end;
  }
}
