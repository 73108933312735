.app-body {
  app-sidebar {
    app-sidebar-nav {
      app-sidebar-nav-items {
        .nav-link {
          padding: .5rem .75rem;
        }
        .nav-title {
          border-top: 1px solid rgba(255, 255, 255, .1);
          border-bottom: 1px solid rgba(255, 255, 255, .1);
          padding: .25rem 0;
          text-align: center;

          .active {
            background-color: inherit;
          }
        }

        .nav-dropdown {
          &.open {
            &+.nav-dropdown-toggle {
              font-weight: bold;
            }
          }

          // .nav-dropdown-items {
          //   padding-left: 1rem;
          //   border-left: 1px solid rgba(255, 255, 255, .05);
          // }
        }
      }

      &.ps {
        app-sidebar-nav-items {
          .nav-dropdown {
            .nav-dropdown-items {
              // padding-left: 0;
              margin-left: 1rem;
              border-left: 1px solid rgba(255, 255, 255, .05);
            }
          }
        }
      }
    }
  }
}
