.card {
  .card-header {
    min-height: var(2rem);

    &.card-header-btn {
      display: flex !important;
      align-items: center;
      // justify-content: space-between;
      padding: .25rem .5rem;
      // height: 2rem;
      // color: #5a5a5a;

      .card-title {
        margin: 0;
        font-weight: bold;
        color: var(--dark);

        i {
          padding-right: .5rem;
          font-size: 1rem;
          color: rgba(90, 90, 90, .75);
        }
      }

      .card-actions {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: auto;

        & > * {
          &:not(:last-child) {
            margin-right: .25rem !important;
          }
        }

        .filter {
          display: flex;

          & > * {
            margin-left: .25rem !important;
          }
        }

        button {
          height: 100%;
          border: none;
          background-color: transparent;
          font-size: 1rem;
          color: rgba(90, 90, 90, .75);

          &:hover {
            color: rgba(90, 90, 90, 1);
          }
        }
      }
    }
  }
}

// Controle do Scroll da página dentro do Card-Body
.app-body {
  .main {
    height: calc(100vh - 120px);
    overflow: hidden;

    .container-fluid {
      position: relative;
      height: calc(100% - 62px);

      & > * {
        height: inherit;

        .animated.fadeIn {
          height: 100%;

          .card {
            position: relative;
            max-height: 100%;

            .card-body {
              max-height: calc(100% - var(--card-header));
              overflow: auto;
            }
          }

          .container {
            max-height: 100%;
            overflow: auto;
          }
        }
      }
    }
  }
}
